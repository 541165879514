import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function AdminToken() {
  const [isCopied, setIsCopied] = useState(false);

  const allStates = useSelector((state) => state);
  const isAdminLoggedIn = allStates.reducer1;
  const isAdminTokenIn = allStates.reducer3;
  console.log(isAdminTokenIn, "isAdminTokenIn");
  console.log(isAdminTokenIn, "isAdminTokenIn");
  document.addEventListener("DOMContentLoaded", function () {
    // Trigger the animation by adding a class
    document.querySelector(".adminPanelLoginBox h3").classList.add("animate");
  });
  const handleCopyToClipboard = () => {
    // Select the textarea
    const textarea = document.querySelector(".adminPanelLoginBox textarea");

    // Select the text content of the textarea
    textarea.select();

    // Copy the text to the clipboard
    document.execCommand("copy");

    // Deselect the textarea
    textarea.setSelectionRange(0, 0);
    textarea.blur();

    setIsCopied(true);

    // Hide the success message after 3 seconds (adjust the duration as needed)
    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  return (
    <div className="adminPanelLoginOuterBox">
      <div className="adminPanelLoginInnerBox">
        <div className="adminPanelLoginBox">
          <h3 className="animate">Token</h3>
          <textarea
            style={{
              minHeight: "175px",
              width: "90%",
              padding: "10px",
              fontSize: "16px",
            }}
            value={isAdminTokenIn.token}
            readOnly
          />
          <button
            onClick={handleCopyToClipboard}
            className="adminTokenCopyButton"
          >
            Copy to Clipboard
          </button>
          {isCopied && (
            <div className="successMessage">Copied to Clipboard!</div>
          )}
        </div>
      </div>
    </div>
  );
}
