/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import "./App.css";
import { useSelector } from "react-redux";
import AdminPanelLogin from "./Components/Pages/AdminPanel/AdminPanelLogin/AdminPanelLogin";
import AdminToken from "./Components/Pages/AdminPanel/AdminPanelLogin/AdminToken";

function App() {
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let isAuthenticated = "";
  if (adminLoggedIn.toString() === "1") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "2") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "3") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "4") {
    isAuthenticated = true;
  } else if (adminLoggedIn.toString() === "5") {
    isAuthenticated = true;
  } else isAuthenticated = false;
  const userDetails = allStates.reducer1;

  const navigate = useNavigate();

  // Use useEffect to navigate to the '/' route when the component mounts
  useEffect(() => {
    navigate("/adminpanellogin");
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AdminPanelLogin />}></Route>
        <Route path="/adminpanellogin" element={<AdminPanelLogin />}></Route>
        <Route
          path="/admin_token"
          element={isAuthenticated ? <AdminToken /> : <AdminPanelLogin />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
