import React, { useEffect, useState } from "react";
import logo from "../../../Images/loyalStringLogoWide.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLoggedIn } from "../../../../redux/action/Actions";
import { addToken } from "../../../../redux/action/Actions";
// import "../../PagesStyles/AdminPanelLogin.css";
import "../../PageStyles/AdminPanelLogin.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdLogin } from "react-icons/md";
import { a62 } from "../../../Api/RootApiPath";

export default function AdminPanelLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const loggedIn = (user) => {
    // dispatch(adminLoggedIn(user));
    dispatch(adminLoggedIn(user));
  };
  const addingToken = (user) => {
    // dispatch(adminLoggedIn(user));
    dispatch(addToken(user));
  };
  useEffect(() => {
    let showPasswordOption = document.getElementById(
      "adminPanelLoginFormShowPasswordButton"
    );
    if (showPassword === true) {
      showPasswordOption.classList.add("passwordVisible");
      showPasswordOption.classList.remove("passwordHidden");
    } else {
      showPasswordOption.classList.add("passwordHidden");
      showPasswordOption.classList.remove("passwordVisible");
    }
  }, [showPassword]);

  // const handleLogin = () => {
  //   if (username == "admin" && password == "Skkhandre@123") {
  //     // alert(`Welcome ${username}`);
  //     loggedIn("1");
  //     navigate("/adminhome");
  //   } else if (username == "Branch2" && password == "Skkhandre@123") {
  //     // alert(`Welcome ${username}`);
  //     navigate("/adminhome");
  //     loggedIn("2");
  //   } else if (username == "Staff2" && password == "soccerlover") {
  //     // alert(`Welcome ${username}`);
  //     navigate("/adminhome");
  //     loggedIn("3");
  //   } else if (username == "Staff3" && password == "chocolate123") {
  //     // alert(`Welcome ${username}`);
  //     navigate("/adminhome");
  //     loggedIn("4");
  //   } else if (username == "Staff4" && password == "beach2020") {
  //     // alert(`Welcome ${username}`);
  //     navigate("/adminhome");
  //     loggedIn("5");
  //   } else {
  //     alert("Sorry Incorrect Details");
  //   }
  // };
  const [error, setError] = useState(false);
  const handleLogin = async () => {
    // Assuming it's a GET request, append parameters to the URL
    const apiUrl = `${a62}?email=${username}&password=${password}`;

    try {
      const response = await fetch(a62, {
        method: "POST", // Use POST method
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      });
      const data = await response.json();

      // Process the response data, e.g., store the token
      const token = data.token;
      // Perform actions with the token, such as storing it in state or localStorage
      if (data.token) {
        console.log(data.token);
        loggedIn("1");
        addingToken(`${data.token}`);
        // console.log("Login successful. Token:", token);
        navigate("/admin_token");
        // showUser();
      } else {
        showError();
      }
      // Optionally, return the token or other relevant data
      return token;
      // Rest of the code remains the same...
    } catch (error) {
      console.error("Error during login:", error.message);
      showError();
      throw error;
    }
  };
  const showError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3500);
  };
  // const allStates = useSelector((state) => state);
  // const isAdminLoggedIn = allStates.reducer1;
  // const isAdminTokenIn = allStates.reducer3;
  // const showUser = () => {
  //   console.log(isAdminLoggedIn, "isAdminLoggedIn");
  //   console.log(isAdminTokenIn, "isAdminToken");
  // };

  return (
    <div className="adminPanelLoginOuterBox">
      <div className="adminPanelLoginInnerBox">
        <div className="adminPanelLoginBox">
          <img
            style={{ placeSelf: "flex-start", marginBottom: "30px" }}
            className="adminPanelLoginLogo"
            src={logo}
            alt="loyalStringLogo"
          />
          {/* <form onSubmit={handleLogin}> */}
          <div>
            {/* <div className="adminPanelLoginLogoStatementOuter">
              <div className="adminPanelLoginLogoStatement">
                <p>Enter your username and password to access admin panel.</p>
              </div>
            </div> */}
            <div className="adminPanelLoginFormLabelBox">
              <div className="adminPanelLoginFormLabelInnerBox">
                <label htmlFor="inputEmail">Username</label>
              </div>
            </div>
            <div className="adminPanelLoginFormInputBox">
              {/* <div className="adminPanelLoginFormInputInnerBox"> */}
              <input
                type="text"
                id="inputEmail"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {/* </div> */}
            </div>

            <div className="adminPanelLoginFormLabelBox">
              <div className="adminPanelLoginFormLabelInnerBox">
                <label htmlFor="inputPassword">Password</label>
              </div>
            </div>
            <div className="adminPanelLoginFormInputBox">
              <div className="adminPanelLoginFormInputInnerBox">
                <input
                  type={showPassword ? "text" : "password"}
                  id="inputPassword3"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  style={{ cursor: "pointer" }}
                  id="adminPanelLoginFormShowPasswordButton"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEyeInvisible className="adminPanelLoginFormShowPasswordIcon" />
                  ) : (
                    <AiOutlineEye className="adminPanelLoginFormShowPasswordIcon" />
                  )}
                </button>
              </div>
            </div>

            <div className="adminPanelLoginFormButtonOuterBox">
              <button
                type="submit"
                onClick={() => handleLogin()}
                className="adminPanelLoginFormButtonInnerBox"
              >
                <MdLogin style={{ marginRight: "5px" }} />
                <div>Sign in</div>
              </button>
            </div>
            {/* </form> */}
          </div>
          {error && <div className="errorMessage">Login Failed</div>}
        </div>
      </div>
    </div>
  );
}
